import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerSecondary from "../../components/banners/bannerSecondary"
import Why from "../../components/why"
import Benefits from "../../components/benefits"
import Reviews from "../../components/reviews"
import Treatments from "../../components/treatments"
import CallToAction from "../../components/callToAction"
import FeaturedFaq from "../../components/faqs/featuredFaq"
import {
  Section,
  Container,
  Flex,
  FlexMobileOpp,
} from "../../components/layoutComponents"
import { StaticImage } from "gatsby-plugin-image"
import Calander from "../../components/calander"
import HotStoneFaq from "../../components/faqs/HotStoneFaq"

export default function HotStoneMassageTherapyCalgary() {
  return (
    <Layout>
      <SEO
        title="Hot Stone Massage near Calgary - Get The Treatment You Deserve"
        description="Our hot stone Massage services in Calgary will have you healed!"
      />
      <BannerSecondary
        title="hot stone massage near calgary"
        description="Combining skillful hot stone massage technique with sound therapy to leave you feeling tension free, relaxed, and revitalized."
        to1="/"
        link1="home"
        to2="/treatments"
        link2="treatments"
        to3="/treatments/hot-stone-massage-calgary"
        link3="hot stone massage calgary"
      />
      <Section>
        <Container className="spacing">
          <div className="center">
            <h1 className="title bold caps">Hot stone Massage Calgary </h1>{" "}
            <p className="accent upper">
              in combination with sound vibration therapy
            </p>
          </div>
          <Flex>
            <div>
              <p>
                Pacha Mamma Massage & Sound Therapy now offers the amazing,
                relaxing and soothing treatment of hot stone massage Calgary
                (also known as hot rock massage). Hot Stone therapy is an
                ancient treatment that offers heat to the muscles by way of a
                warmed smooth stone massaged into the area.
              </p>{" "}
              <br />
              <p>
                8 Benefits of hot stone massage services in Calgary, AB include:
              </p>
              <ul>
                <li>Helps relieve muscle tension</li>
                <li> Improved flexibility & mobility</li>
                <li>Reduces stress and anxiety</li>
                <li>Helps to promote sleep</li>
                <li>
                  May reduce symptoms of Fibromyalgia pain or chronic pain
                </li>
                <li>May boost immunity</li>
                <li>Improved circulation</li>
              </ul>
              <p>
                During your session, you may feel peaceful and relaxed, and may
                fall asleep. Many of our SW Calgary hot stone massage clients
                report having a deep, restful sleep the night of their sessions.
              </p>
            </div>
            <StaticImage
              src="../../images/hot-stone-massage-calgary.jpg"
              alt="best hot stone massage calgary, AB"
              className="stretch"
            />
          </Flex>
        </Container>
      </Section>
      <HotStoneFaq />
      <Reviews />
      <CallToAction />
      <Why />
      <Benefits />
      <Treatments />
      <CallToAction />
      <Calander />
    </Layout>
  )
}
