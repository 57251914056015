import React, { useState } from "react"
import styled from "styled-components"
import { Section, Container, Flex } from "../layoutComponents"
import { ButtonUnderline } from "../buttons"

import ArrowDown from "../../images/arrow-down.svg"

const device = {
  md: "48em",
}

const AccordionWrapper = styled.div`
  border-bottom: ${({ item }) =>
    item ? "none" : "1px solid var(--clr-accent)"};
`

const TitleFlex = styled.div`
  padding: var(--spacer-sm);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ item }) => (item ? "4px solid #9ecaed" : "none")};

  &:hover {
    cursor: pointer;
    background: var(--clr-light-secondary);
    transition: 0.25s ease-in;
  }
`

const Text = styled.div`
  align-self: start;
`

const AccordionContent = styled.div`
  display: ${({ item }) => (item ? "block" : "none")};
`

const ArrowImg = styled.img`
  width: 25px;
  transform: ${({ item }) => (item ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.25s;
`

const Spacer = styled.div`
  margin-bottom: var(--spacer);
`

const AccordionItem = props => {
  const [item, itemOpen] = useState(false)

  function toggleItem() {
    itemOpen(!item)
  }
  return (
    <AccordionWrapper item={item}>
      <TitleFlex item={item} onClick={toggleItem}>
        <p className="bold">{props.question}</p>
        <ArrowImg item={item} src={ArrowDown} alt="" />
      </TitleFlex>
      <AccordionContent className="spacing" item={item}>
        <div className="spacing">
          <p>{props.answer}</p>
        </div>
        <Spacer />
      </AccordionContent>
    </AccordionWrapper>
  )
}

export default function HotStoneFaq() {
  return (
    <Section>
      <Container className="spacing-lg">
        <Flex>
          <Text className="spacing">
            <h2 className="title--bold italics accent">
              get your hot stone massage questions answered
            </h2>
            <p>
              If you are new to hot stone massage services Calgary, AB, we
              understand you might have some quetions. Feel free to contact us,
              or visit our full FAQs page to get all your questions answered!
              Check with your doctor before requesting a hot stone massage. As
              some conditions may need to be considered before treatment.
            </p>
          </Text>
          <div>
            <AccordionItem
              question="What happens during a hot stone massage?"
              answer="Hot stone massage therapy in Calgary is much like a regular massage. The therapist uses warmed flat and smooth Basalt stones. Each stone is placed on strategic areas of your body to help your muscles relax and warm the area. The therapist then massages with the stones to increase relaxation and alleviate muscle tension."
            />
            <AccordionItem
              question="How much does a hot stone massage cost?"
              answer="At Pacha Mamma Massage & Sound Therapy, the cost is the same as a regular one hour massage. Just ask for a “hot stone massage” at the start of your session and our Calgary therapists will be sure to help you."
            />
          </div>
        </Flex>
      </Container>
    </Section>
  )
}
